import React from "react";
import WithFooter from "./WithFooter";

import "./formstyle.css";
import "./globalstylesheet-sai.css";
import "./globalstylesheet.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/system";
import Slider from "react-slick";
function Paymentsv1() {
  const isMobile = useMediaQuery("(max-width: 568px)");

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
  };
  return (
    <WithFooter>
      <div class="payments">
        <div class="hero">
          <div class="hero__herosection">
            <img
              src="images/homebanner-bg-0.png"
              alt=""
              class="hero__herosection__img1"
            />
            <img
              src="images/homebanner-bg-1.svg"
              alt=""
              class="hero__herosection__img2"
            />
            <div class="hero__content">
              <div class="hero__content__div">
                <div class="hero__content__div__text">
                  <div class="hero__content__div__text__heading">
                    We have your <br />
                    Payments covered
                  </div>
                  <div class="hero__content__div__text__content">
                    We've combined creating an online store, payment, shipping
                    and shop management into a single, easy-to-use platform.
                  </div>
                  <div class="hero__content__div__text__button">
                    <Link to={"/auth/signup"}>Get started — it's free</Link>
                  </div>
                </div>
                <div class="hero__content__div__image">
                  <img src="images/payments-hero-sticitt-img.svg" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payments__container">
          <div class="payments__container__toppart">
            <div class="payments__container__toppart__heading">
              Business Simplified I Payments Streamlined
            </div>
            {/* <div class="payments__container__toppart__box">
              <div class="payments__container__toppart__box__content">
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Multiple Payment Methods
                    </div>
                    <div class="text">
                      Peach Payments offers all major credit and debit cards,
                      Pay by Bank, Mobicred, Masterpass, 1ForYou, and Apple Pay.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Recurring Payments
                    </div>
                    <div class="text">
                      Peach Payment's card tokenization technology simplifies
                      subscription payments and recurring billing.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
              <div class="payments__container__toppart__box__content">
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Conversion Focused
                    </div>
                    <div class="text">
                      With the highest transaction success rate in the market,
                      Peach Payments ensures you don't miss a sale.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="payments__container__toppart__box__content__div">
                  <div class="payments__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Sell Globally
                    </div>
                    <div class="text">
                      Accept payments in international currencies and expand
                      your business across Africa and the world.
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
            </div> */}
            <div class="shipping__container__toppart__box">
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                      Multiple Payment Methods
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: isMobile?"15vh": "35vh",
                        // alignItems: "center",
                        paddingTop:isMobile?"10%": "15%",
                      }}
                    >
                      <div class="text">
                        We offer a diverse range of payment options to
                        accommodate customers, accepting various online payment
                        types.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                    Conversion Focused
                     
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: isMobile?"15vh": "35vh",
                        // alignItems: "center",
                        paddingTop:isMobile?"10%": "15%",
                      }}
                    >
                      <div class="text">
                      Don’t let your customers abandon their cart because
                        their preferred, trusted, or local payment provider
                        wasn’t offered at checkout. We have the tools you need
                        for an efficient, and flexible payment process.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
              <div class="shipping__container__toppart__box__content">
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div
                      class="heading"
                      style={{
                        color: "#688ad5",
                      }}
                    >
                      Sell Globally
                    </div>
                    <div
                      style={{
                        display: "flex",
                        minHeight: isMobile?"15vh": "35vh",
                        // alignItems: "center",
                        paddingTop:isMobile?"10%": "15%",
                      }}
                    >
                      <div class="text">
                     
                      Accept payments in international currencies and expand
                        your business across Africa and the world.
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
                <div class="shipping__container__toppart__box__content__div">
                  <div class="shipping__container__toppart__box__content__div__wrapper">
                    <div class="heading" style={{ color: "#688ad5" }}>
                     
                      Incredible local support
                    </div>

                    <div
                      style={{
                        display: "flex",
                        minHeight: isMobile?"15vh": "35vh",
                        // alignItems: "center",
                        paddingTop:isMobile?"10%": "15%",
                      }}
                    >
                      <div class="text">
                      Get to know our South African Onboarding Team by name.
                      We’re with you every step of the way.
                       
                      </div>
                    </div>
                  </div>
                  <div class="bg"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="payments__container__partners">
            <div class="payments__container__partners__div">
              <div class="payments__container__partners__div__button">
                <Link to={"/auth/signup"}>Get started — it's free</Link>
                {/* <a href="Login2.html">Get started — it's free</a> */}
              </div>
              <div class="payments__container__partners__div__head" style={{textAlign:isMobile?"center":""}}>
                <span></span>
                <span>Trusted And Supported By</span>
                <span></span>
              </div>
              <div
            className={isMobile ? "" : "homepage__partners__div__brandlogos"}
          >
            {isMobile ? (
              <div className="image-slider-container" style={{padding:"1rem"}}>
                <Slider {...carouselSettings}>
                  <div>
                  <img src="images/brandimg1.svg" alt="brandimg1" />
                  </div>
                  <div>
                  <img src="images/brandimg2.svg" alt="brandimg2" />
                  </div>
                  <div >
                  <img src="images/brandimg3.svg" alt="brandimg3" />
                  </div>
                  <div >
                  <img src="images/black-landscape (1).svg" style={{width:"55%",margin:"auto", objectFit:"contain"}} />
            
                  </div>
                  <div>
                  <img src="images/brandimg4.svg" alt="brandimg4" />
                  </div>
                </Slider>
              </div>
            ) : (
              <>
                <div style={{ width: "25%" }}>
                  <img src="images/brandimg1.svg" alt="brandimg1" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg2.svg" alt="brandimg2" />
                </div>
                {/* </div> */}
                {/* <div className="homepage__partners__div__brandlogos__c1"> */}
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg3.svg" alt="brandimg3" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/sticitt-landscape.svg" alt="brandimg3" />
                </div>
                <div style={{ width: "20%" }}>
                  <img src="images/brandimg4.svg" alt="brandimg4" />
                </div>
              </>
            )}
          </div>
            </div>
          </div>
        </div>
        <div class="homepage__support">
          <div class="homepage__support__div">
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg1.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Responsive Support</div>
                <div>
                  Prompt assistance and swift resolution to any inquiries,
                  concerns, or issues.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg2.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Simplified Marketing Tools</div>
                <div>
                  Streamline promotional efforts with easy to use and efficient
                  marketing tools.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
            <div class="homepage__support__div__card">
              <div class="homepage__support__div__card__image">
                <img src="images/supportimg3.svg" alt="Image" />
                <div class="roundshape"></div>
              </div>
              <div class="homepage__support__div__card__content">
                <div>Mobile Optimized</div>
                <div>
                  A highly responsive interface for effortless mobile shopping.
                </div>
              </div>
              <div class="homepage__support__div__card__nextbtn">
                <Link to={"/auth/signup"}>
                  <div class="homepage__support__div__card__nextbtn__imgdiv">
                    <i class="bi bi-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </WithFooter>
  );
}

export default Paymentsv1;
